import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-53cbcbd4"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "OrderCard",
  id: "orderCard"
}
const _hoisted_2 = { style: {"height":"200px"} }
const _hoisted_3 = { class: "dialog-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Order = _resolveComponent("Order")!
  const _component_CardForm = _resolveComponent("CardForm")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_el_form, {
        ref: "formRef",
        model: _ctx.form,
        rules: _ctx.rules,
        "label-width": "130px"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_CardForm, {
            ownerInst: _ctx.proxy,
            formItems: _ctx.compParams.formItems,
            form: _ctx.form,
            formRef: _ctx.formRef,
            rules: _ctx.rules,
            disabled: _ctx.disabled,
            refMap: _ctx.refMap,
            colNum: 2
          }, {
            extendSlot: _withCtx(() => []),
            default: _withCtx(() => [
              _createVNode(_component_Order, {
                ref: el=>_ctx.refMap.set('order',el),
                priceFlag: _ctx.otherParams.order.priceFlag,
                sumAmountFlag: _ctx.otherParams.order.sumAmountFlag,
                numEditFlag: _ctx.otherParams.order.numEditFlag,
                pccFlag: _ctx.otherParams.order.pccFlag,
                otherInfoFlag: _ctx.otherParams.order.otherInfoFlag,
                addBtnFlag: _ctx.otherParams.order.addBtnFlag,
                disableCustomer: _ctx.otherParams.order.disableCustomer,
                customerDelivery: _ctx.otherParams.order.customerDelivery,
                disablePcc: _ctx.otherParams.order.disablePcc
              }, null, 8, ["priceFlag", "sumAmountFlag", "numEditFlag", "pccFlag", "otherInfoFlag", "addBtnFlag", "disableCustomer", "customerDelivery", "disablePcc"])
            ]),
            _: 1
          }, 8, ["ownerInst", "formItems", "form", "formRef", "rules", "disabled", "refMap"])
        ]),
        _: 1
      }, 8, ["model", "rules"])
    ]),
    _createVNode(_component_el_dialog, {
      title: "审核订单",
      modelValue: _ctx.otherParams.processInfo.visible,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.otherParams.processInfo.visible) = $event)),
      draggable: "",
      width: "40%",
      "close-on-click-modal": false,
      "append-to-body": ""
    }, {
      footer: _withCtx(() => [
        _createElementVNode("span", _hoisted_3, [
          _createVNode(_component_el_button, {
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.otherParams.processInfo.visible=false))
          }, {
            default: _withCtx(() => [
              _createTextVNode("取 消")
            ]),
            _: 1
          }),
          _createVNode(_component_el_button, {
            type: "primary",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.processOrder()))
          }, {
            default: _withCtx(() => [
              _createTextVNode("确 定")
            ]),
            _: 1
          })
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_el_row, { class: "rowCls" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_col, {
                span: 6,
                class: "colName"
              }, {
                default: _withCtx(() => [
                  _createTextVNode("附言：")
                ]),
                _: 1
              }),
              _createVNode(_component_el_col, { span: 18 }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    type: "textarea",
                    maxlength: "125",
                    rows: "6",
                    modelValue: _ctx.otherParams.processInfo.shDesc,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.otherParams.processInfo.shDesc) = $event)),
                    placeholder: "请输入附言......"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}